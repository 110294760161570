/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~font-awesome/css/font-awesome.css";
@import "assets/css/angulr.css";
@import "~@ng-select/ng-select/themes/default.theme.css";

/* for tost notification */
@import 'ngx-toastr/toastr';

.insta-breadcrumb {
    padding: 8px 0 !important;
    background-color: transparent;
    margin-bottom: 0;
}

/* Display Validation Errors */
.form-group .text-danger {
    position: absolute;
    bottom: -20px;
    right: 16px;
}

/* CSS Style for multi-select dropdown starts */
button.dropdown-toggle.multiselect-items.btn.btn-default {
    text-align: left;
    width: 190px;
}

.dropdown.dropdown-inline {
    width: 100%;
}

.col-md-2 button.dropdown-toggle.multiselect-items.btn.btn-default {
    text-align: left;
    width: 100% !important;
}

.col-md-6 button.dropdown-toggle.multiselect-items.btn.btn-default {
    text-align: left;
    width: 253.75px !important;
}

ss-multiselect-dropdown .dropdown-item {
    padding: 5px 0;
}

ss-multiselect-dropdown .dropdown-menu:not(.head-list)>li>a:hover {
    background-color: transparent;
    color: #000000;
}

ss-multiselect-dropdown li.dropdown-item.search {
    padding: 6px 6px 0 6px;
}

ss-multiselect-dropdown .search input.form-control {
    height: 30px !important;
}

.ng-value-container {
    display: block !important;
    height: auto !important;
    max-height: 30px !important;
}

/* CSS Style for multi-select dropdown ends */

/* CSS For Form Validations starts */

.form-control.ng-invalid {
    border-left: 2px solid #a94442;
    /* red */
}

/* CSS For Form Validations ends */

/* CSS for Search Result DropDown starts */

.no-search-result-dropdown {
    display: block;
    top: 44px;
    left: 256px;
    position: absolute;
    z-index: 1000;
    float: left;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 14px;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}

/* CSS for Search Result DropDown ends */

/* CSS for Vcourt highlighter  */

.highlight-text {
    padding: 0em;
    background-color: #f9d412;
}

/* CSS for Image Frame */
.identity-edit-image-frame {
    max-width: 800px;
    overflow-x: scroll;
    overflow-y: scroll;
}

td.positive {
    background-color: #dff0d8;
    border-color: #d6e9c6;
}

td.negative {
    background-color: #f2dede;
    border-color: #ebccd1;
}

select.positive {
    background-color: #dff0d8;
    border-color: #d6e9c6;
}

select.negative {
    background-color: #f2dede;
    border-color: #ebccd1;
}

.form-control[disabled].negative,
.form-control[readonly],
fieldset[disabled] .form-control {
    background-color: #f2dede !important;
    opacity: 1;
}

.form-control[disabled].positive,
.form-control[readonly],
fieldset[disabled] .form-control {
    background-color: #dff0d8 !important;
    opacity: 1;
}

.form-control[disabled].warning,
.form-control[readonly],
fieldset[disabled] .form-control {
    background-color: #fcf8e3 !important;
    opacity: 1;
}

.label-danger {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
}

.label-success {
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #d6e9c6;
}

.label-warning {
    color: #8a6d3b;
    background-color: #fcf8e3;
    border-color: #faebcc;
}

.blue-text {
    font-weight: 400;
    color: #337ab7;
    border-radius: 0;
}

.sms-btn-warning {
    color: #8a6d3b;
    background-color: #fcf8e3;
    border-color: #faebcc;
}

.sms-btn-success {
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #d6e9c6;
}

.sms-btn-danger {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
}

.loading {
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1050;
}

.loading-content {
    position: relative;
    top: 50%;
    left: 35%;
    height: 85px;
    width: 415px;
    color: #fff;
    bottom: 0;
    z-index: 1;
    font-family: Helvetica, Arial, sans-serif;
    font-size: large;
}

.stats-collapse-panel {
    padding: 0rem !important;
}

.stats-list {
    margin-bottom: 0px !important;
}

.stats-warning {
    background-color: #fcf8e3 !important;
    text-align: center !important;
}

.stats-positive {
    background-color: #dff0d8 !important;
    text-align: center !important;
}

.stats-negative {
    background-color: #f2dede !important;
    text-align: center !important;
}

.stats-white {
    text-align: center !important;
}

.col-md-7 button.dropdown-toggle.multiselect-items.btn.btn-default {
    text-align: left;
    width: 513px !important;
}

#stats {
    margin-left: 90%;
}

.margin-bottom-10 {
    margin-bottom: 10px;
}

.margin-bottom-20 {
    margin-bottom: 20px;
}

.padding-left-none {
    padding-left: 0px;
}

.padding-left-5 {
    padding-left: 5px;
}

.padding-top-0-6 {
    padding-top: 0.6rem;
}

.btn-space {
    margin-right: 10px;
}

.list-page-buttons {
    padding-left: 20px;
    padding-right: 20px;
}

.pcc-verification {
    margin-bottom: 10px;
    padding-top: 1rem;
}

/*html, body { height: 100%; }*/
/*body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }*/

.dropdown-menu>a {
    padding: 5px 15px !important;
    display: block !important;
}

.ng-dropdown-panel {
    width: inherit !important;
}

.ng-dropdown-panel .ng-dropdown-header,
.ng-option {
    min-width: 265px !important;
}

.ng-option-selected:before {
    content: "\2713  ";
}

.textarea-height {
    height: 109px !important;
}

app-client-upload .ng-select-multiple.ng-invalid {
    border-left: 2px solid #a94442;
}

app-client-upload .ng-select-searchable.ng-invalid {
    border-left: 2px solid #a94442;
}

.cke_notification_warning {
    display: none;
}

.vendor-menu {
    max-height: 300px;
    overflow-y: scroll
}

app-multi-select .ng-dropdown-panel {
    width: auto !important;
}

app-client-create .ng-select-searchable.ng-invalid {
    border-left: 2px solid #a94442;
}

app-client-edit .ng-select-searchable.ng-invalid {
    border-left: 2px solid #a94442;
}